import { ActionCallback, Button, Loading, Modal, Redirect, Toast } from "@shopify/app-bridge/actions";
import { ClientApplication } from "@shopify/app-bridge";

// for info on how to use the Shopify "actions" below, see https://shopify.dev/docs/api/app-bridge/previous-versions/actions
export class ShopifyUtil {

  public static appRedirect(app: ClientApplication, path: string) {
    Redirect.create(app).dispatch(Redirect.Action.APP, path);
  }

  public static externalRedirect(app: ClientApplication, url: string, newWindow: boolean) {
    Redirect.create(app).dispatch(Redirect.Action.REMOTE, {
      url,
      newContext: newWindow
    });
  }

  public static toast(app: ClientApplication, message: string, duration = 2_000) {
    Toast.create(app, {
      message,
      duration
    }).dispatch(Toast.Action.SHOW);
  }

  public static createButton(app: ClientApplication, label: string, action: ActionCallback) {
    let jenson = Button.create(app, {label});
    jenson.subscribe(Button.Action.CLICK, action);
    return jenson;
  }

  public static showModal(app: ClientApplication, title: string, path: string) {
    const modalOptions = {
      title,
      path,
      size: Modal.Size.Large
    };
    const modal = Modal.create(app, modalOptions);
    modal.dispatch(Modal.Action.OPEN);
    return modal;
  }

  public static startLoading(app: ClientApplication) {
    return Loading.create(app).dispatch(Loading.Action.START);
  }

  public static stopLoading(loading: Loading.Loading) {
    return loading.dispatch(Loading.Action.STOP);
  }

  // TODO this can be used to obtain a shopify session token - don't remove, may need it later
  private getShopifyAccessToken() {
    // 1) add this package: npm i @shopify/app-bridge-utils
    //
    // 2) add this import:
    // import { getSessionToken } from "@shopify/app-bridge-utils/utilities/session-token";
    //
    // 3) then get a token like this
    // const token = await getSessionToken(app);
    // console.log("shopify sessiontoken: " + token);
  }
}