import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { MessageService } from "../../modules/shared/message/message.service";
import { ProgressBarService } from "../../modules/shared/progress-bar/progress-bar.service";
import { LocalStorageKey } from "../../local-storage/local-storage-keys";
import { BaseHttpService } from "../base-http.service";
import { HttpClient } from "@angular/common/http";
import { LocalStorageService } from "../../local-storage/local-storage.service";
import { ChangePassword } from "../../model/changepassword";
import { bootIntercom } from "../../util/intercom.util";
import { TranslateService } from "@ngx-translate/core";
import { User } from "../../model";
import { environment } from "../../../environments/environment";
import { CombiPackageLoadUserResult } from "../combipackage/combipackage.service";
import { firstValueFrom } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class UserService extends BaseHttpService {

  private user: User;
  private adminUser: User;

  constructor(protected http: HttpClient,
              protected progressBarService: ProgressBarService,
              protected messageService: MessageService,
              private translate: TranslateService,
              private localStorageService: LocalStorageService,
              private router: Router) {
    super(http, null, translate, progressBarService, messageService);
  }

  isLoggedIn(): boolean {
    if (this.user && !this.user.temporaryUser) {
      return true;
    }

    const user = this.localStorageService.get<User>(LocalStorageKey.USER);

    if (user === null) {
      return false;
    } else {
      this.user = user;
      return user && user.loggedIn && !user.temporaryUser;
    }
  }

  getUser(): User {
    if (!this.user) {
      this.user = this.localStorageService.get<User>(LocalStorageKey.USER);
    }
    return this.user;
  }

  setUser(user: User, alsoBootIntercom = true): void {
    this.user = user;
    this.localStorageService.set(LocalStorageKey.USER, user);
    if (this.user?.language) {
      this.translate.use(this.user.language);
    }

    if (alsoBootIntercom) {
      bootIntercom(this.translate.currentLang, user);
    }
  }

  isSuperUser(): boolean {
    return this.getUser().cindyLoper || !!this.getAdminUser();
  }

  getAdminUser(): User {
    if (this.adminUser === undefined) {
      this.adminUser = this.localStorageService.get<User>(LocalStorageKey.ADMIN_USER);
    }
    return this.adminUser;
  }

  setAdminUser(adminUser: User): void {
    this.adminUser = adminUser;
    this.localStorageService.set(LocalStorageKey.ADMIN_USER, adminUser);
  }

  async login(email: string, password: string): Promise<boolean> {
    const user = this.getUser() || <User>{};
    this.clearLocalStorage();
    this.localStorageService.remove(LocalStorageKey.ADMIN_USER);
    user.email = email;
    user.password = password;
    await this.completeUserBeforeSaving(user);
    this.setUser((await this.post<User>("unauthenticated/user/login", {user})).result);
    return this.isLoggedIn();
  }

  async autoLogin(token: string): Promise<boolean> {
    const loginAsUser = (await this.get<User>(`unauthenticated/user/autologin/${token}`)).result;
    if (this.getUser() && this.getUser().encryptedID !== loginAsUser?.encryptedID) {
      this.messageService.showErrorMessage("Log eerst even uit - check al je brower tabs!");
      return;
    }
    this.clearLocalStorage();
    this.setUser(loginAsUser);
    return this.isLoggedIn();
  }

  async autoLoginAdmin(token: string): Promise<boolean> {
    const loginAsUser = (await this.get<User>(`unauthenticated/user/autologinadmin/${token}`)).result;
    if (this.getUser() && this.getUser().encryptedID !== loginAsUser?.encryptedID) {
      this.messageService.showErrorMessage("Log eerst even uit - check al je brower tabs!");
      return;
    }
    this.clearLocalStorage();
    this.setUser(loginAsUser);
    return this.isLoggedIn();
  }

  async register(email: string, password: string, newsletter: boolean): Promise<boolean> {
    const user = this.getUser() || <User>{};
    this.clearLocalStorage();
    user.email = email;
    user.password = password;
    user.newsletter = newsletter;
    await this.completeUserBeforeSaving(user);
    this.setUser((await this.post<User>("unauthenticated/user/register", {user})).result);
    return this.isLoggedIn();
  }

  async registerTempUser(email?: string): Promise<boolean> {
    const language = this.translate.currentLang;
    const recommendedByUrlParam = this.localStorageService.get(LocalStorageKey.RECOMMENDED_BY_URL_PARAM);
    this.setUser((await this.post<User>("unauthenticated/user/registertemp", {language, recommendedByUrlParam, email})).result);
    return this.isLoggedIn();
  }

  async newsletterSignUp(email: string): Promise<User> {
    return (await this.post<User>("unauthenticated/user/newsletter", {email})).result;
  }

  async resetPassword(user: User): Promise<boolean> {
    const result = (await this.post<User>("unauthenticated/user/resetpasswordforuser", {user}));
    if (result.success) {
      this.setUser(result.result);
    }
    return result.success;
  }

  async loadUser(user: User, withAffiliate: boolean): Promise<User> {
    return (await this.get<User>("unauthenticated/loaduser", {user, withAffiliate})).result;
  }

  async loadUserByAPIOAuthKeyAndUrlService(apiOAuthKey: string, url: string): Promise<CombiPackageLoadUserResult> {
    return (await this.get<CombiPackageLoadUserResult>("unauthenticated/loaduserbyapioauthkeyandurl", {apiOAuthKey, url})).result;
  }

  async loadUserByAPIOAuthKeyAndExternalApiIDService(apiOAuthKey: string, externalApiID: string): Promise<CombiPackageLoadUserResult> {
    return (await this.get<CombiPackageLoadUserResult>("unauthenticated/loaduserbyapioauthkeyandexternalapiid", {apiOAuthKey, externalApiID})).result;
  }

  async changeUser(changeUser: User): Promise<User> {
    const updatedUser = await this.post<User>("authenticated/changeuser/save", {user: this.user, changeUser});
    if (updatedUser) {
      this.setUser(updatedUser.result);
    }
    return this.user;
  }

  async changePassword(changePassword: ChangePassword): Promise<User> {
    this.setUser((await this.post<User>("authenticated/changepassword/save", {user: this.user, changePassword})).result);
    return this.user;
  }

  async recommend(userData: User): Promise<User> {
    const u = (await this.post<User>("unauthenticated/recommend", {user: this.user, userData})).result;
    if (u) {
      this.setUser(u);
    }
    return this.user;
  }

  async confirmRegistration(token: string): Promise<void> {
    this.setUser(<User>(await this.get("unauthenticated/user/register/confirm/" + token)).result);
  }

  async deleteAccount() {
    if ((await this.post("authenticated/user/delete")).success) {
      this.logout();
    }
  }

  async logout() {
    if (this.user.cindyLoper) {
      // logout from backend in case we're a superuser, so userapi logging can be disabled again
      await this.post("authenticated/user/logout", {user: this.user}); // no need to wait, but just in case..
    }
    this.user = undefined;
    this.clearLocalStorage();
    // don't navigate to '/', because when already there, the page won't reload and you don't 'see' you're logged out
    this.router.navigate(["/home"]);
  }

  rememberAffiliateRef(affiliateRef: string) {
    this.localStorageService.set(LocalStorageKey.RECOMMENDED_BY_URL_PARAM, affiliateRef);
    const user = this.getUser();
    if (user) {
      user.recommendedByUrlParam = affiliateRef;
      this.setUser(user); // also saves to LocalStorage
    }
  }

  public clearLocalStorage(): void {
    this.localStorageService.remove(LocalStorageKey.USER);
    this.localStorageService.remove(LocalStorageKey.CURRENT_USERAPI);
    this.localStorageService.remove(LocalStorageKey.OAUTHRESULT);
    this.localStorageService.remove(LocalStorageKey.CP_USERCOMBIPACKAGE);
    this.localStorageService.remove(LocalStorageKey.CP_COMBIPACKAGE);
    this.localStorageService.remove(LocalStorageKey.CP_EMAIL);
    this.localStorageService.remove(LocalStorageKey.CP_PACKAGEPARAMS);
    this.localStorageService.remove(LocalStorageKey.IS_EDIT);
  }

  private async completeUserBeforeSaving(user: User) {
    if (!user.language) {
      user.language = this.translate.currentLang;
    }
    if (!user.recommendedByUrlParam) {
      user.recommendedByUrlParam = this.localStorageService.get(LocalStorageKey.RECOMMENDED_BY_URL_PARAM);
    }
    if (environment.env !== "dev") {
      if (!user.countryCode || !user.city) {
        const res: { country_code: string; city: string } = await firstValueFrom(this.http.get<any>("https://api.ipdata.co/?api-key=8873beb43826ee470c05e6fe055df64b028868a3a71ae05590881134"));
        user.countryCode = res?.country_code;
        user.city = res?.city;
      }
    }
  }
}
